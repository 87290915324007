@import '../../styles/globals.scss';

.contact-box {
  background-color: $white;
  margin: 10px 0 30px 0;
  padding: 40px;
  border: 1px solid #eee;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, .1);

  @include for-medium {
    padding: 30px 20px;
  }
}

.form-label {
  margin: 0 0 4px 2px;
  font-size: 16px;
  font-weight: 500;
  color: $black5;
}

.loading-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
}