@import '../../styles/globals.scss';

.ant-carousel .slick-slide {
  height: 750px;
  overflow: hidden;

  @include for-medium {
    height: 400px;
  }
}

.slider-image {
  background-color: $white;
  background-position: center;
  background-size: cover;
  height: 750px;

  @media (max-width: 768px) {
    height: 400px;
  }

  &-logo {
    width: 500px;

    @media (max-width: 768px) {
      width: 300px;
    }
  }

  &-overlay {
    background-color:rgba($color: $black, $alpha: 0.3);
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &-title {
    text-transform: uppercase;
    color: $white;
    font-size: 70px;
    font-weight: 700;
    text-align: center;
    line-height: 1;

    @media (max-width: 768px) {
      font-size: 30px;
    }
  }

  &-text {
    color: $white;
    font-size: 40px;
    margin-top: 20px;
    text-align: center;
    line-height: 1.2;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  &-button {
    @include white-outline-button;
    margin-top: 30px;
  }

  &.image-1 {
    background-image: url("../../images/dj_outside.png");
  }

  &.image-2 {
    background-image: url("../../images/unforgettable_weddings.png");
  }

  &.image-3 {
    background-image: url("../../images/incredible_parties.png");
  }
}