$blue: #337ab7;

// White
$white: #FFFFFF;

// Black
$black: #000000;
$black1: #101010;
$black2: #202020;
$black3: #303030;
$black4: #404040;
$black5: #505050;
$black6: #606060;
$black7: #707070;
$black8: #808080;
$black9: #909090;

// Gray
$gray1: #F2F2F2;
$gray9: #F9F9F9;

$red: #e74c3c;