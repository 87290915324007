@import '../../styles/globals.scss';

.admin-layout {
  min-height: 100vh;

  .header {
    background-color: $blue;
  }

  .admin-content-background {
    background-color: $gray1;
    padding: 24px;
  }
  
  .admin-content-box {
    background-color: $white;
  }
}
