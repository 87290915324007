.page-section {
  padding: 60px 0;
}
.page-section.gray {
  background-color: #F2F2F2;
}
.page-section.blue {
  background-color: #337ab7;
  color: #FFFFFF;
}
.page-section .page-section-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page-section .page-section-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 1;
}
.page-section .page-section-content {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
  margin-top: 20px;
}
.page-section .page-section-desc {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}
.page-section .page-section-image img {
  width: 100%;
}
.page-section .page-section-button {
  display: inline-block;
  border: 1px solid #303030;
  color: #303030;
  padding: 14px 24px;
  transition: background-color 0.4s, color 0.4s;
  margin-top: 20px;
}
.page-section .page-section-button.white {
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
}
.page-section .page-section-button:hover {
  background-color: #303030;
  color: #FFFFFF;
  cursor: default;
}
.page-section .page-section-button:hover.white {
  background-color: #FFFFFF;
  color: #303030;
}

.center {
  text-align: center;
}

.content-section {
  padding: 50px 0;
}
.content-section .ant-col {
  padding: 0 15px;
}
.content-section h2 {
  color: #505050;
  font-weight: 600 !important;
  font-size: 36px;
  line-height: 1;
  margin-bottom: 16px;
}
.content-section .content-section-text {
  color: #707070;
  font-size: 16px;
}
.content-section img {
  width: 100%;
}
@media (max-width: 992px) {
  .content-section {
    padding: 30px 15px;
  }
  .content-section h2 {
    font-size: 24px;
  }
  .content-section .content-section-text {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .content-section {
    padding: 30px 15px 0 15px;
  }
  .content-section .content-section-text:last-child {
    padding-bottom: 30px;
  }
  .content-section img {
    padding-bottom: 30px;
  }
}
@media (max-width: 480px) {
  .content-section {
    padding: 30px 20px 0 20px;
  }
}

.action-section {
  padding: 50px 0;
  text-align: center;
}
.action-section h2 {
  color: #FFFFFF;
  font-weight: 600 !important;
  font-size: 36px;
  line-height: 1;
  margin-bottom: 16px;
}
.action-section .action-section-text {
  color: #F2F2F2;
  font-size: 16px;
}
.action-section.white-bg h2 {
  color: #505050;
}
.action-section.white-bg .action-section-text {
  color: #707070;
  font-size: 16px;
}
@media (max-width: 992px) {
  .action-section {
    padding: 30px;
  }
  .action-section h2 {
    font-size: 24px;
    line-height: 1.2;
  }
  .action-section .action-section-text {
    font-size: 14px;
  }
}

.white-bg {
  background-color: #FFFFFF;
}

.gray-bg {
  background-color: #F2F2F2;
}

.blue-bg {
  background-color: #337ab7;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #FFFFFF;
}

.page-image {
  height: 500px;
  background-position: center;
  background-size: cover;
}
.page-image-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 30px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}
.page-image-title {
  font-size: 70px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
}
.page-image-subtitle {
  font-size: 50px;
  font-weight: 200;
  line-height: 0.9;
}
.page-image img {
  position: absolute;
}
.page-image.weddings {
  background-image: url("../images/slide_2.jpg");
}

.content-section-button {
  text-transform: uppercase;
  background-color: transparent;
  font-weight: 500;
  display: inline-block;
  padding: 14px 24px;
  transition: background-color 0.4s, color 0.4s;
  border: 1px solid #303030;
  color: #303030;
  margin-top: 20px;
}
.content-section-button:hover {
  cursor: default;
}
.content-section-button:focus {
  outline: none;
}
.content-section-button:hover {
  background-color: #303030;
  color: #fff;
}

.page-title-section {
  background-color: #FFFFFF;
  text-align: center;
  padding: 30px;
}
.page-title-section .page-title-1 {
  font-weight: 200;
  font-size: 40px;
  color: #707070;
  line-height: 1;
}
@media (max-width: 768px) {
  .page-title-section .page-title-1 {
    font-size: 30px;
  }
}
.page-title-section .page-title-2 {
  font-weight: 600;
  font-size: 60px;
  text-transform: uppercase;
  color: #505050;
  line-height: 1;
}
@media (max-width: 768px) {
  .page-title-section .page-title-2 {
    font-size: 40px;
  }
}

.page-image-section {
  background-color: #FFFFFF;
  text-align: center;
}
.page-image-section img {
  width: 95%;
}

.ant-card-meta-title {
  text-align: center;
  font-size: 20px;
}

.white-outline-button {
  text-transform: uppercase;
  background-color: transparent;
  font-weight: 500;
  display: inline-block;
  padding: 14px 24px;
  transition: background-color 0.4s, color 0.4s;
  border: 1px solid #fff;
  color: #fff;
}
.white-outline-button:hover {
  cursor: default;
}
.white-outline-button:focus {
  outline: none;
}
.white-outline-button:hover {
  background-color: #fff;
  color: #000;
}

.black-outline-button {
  text-transform: uppercase;
  background-color: transparent;
  font-weight: 500;
  display: inline-block;
  padding: 14px 24px;
  transition: background-color 0.4s, color 0.4s;
  border: 1px solid #303030;
  color: #303030;
}
.black-outline-button:hover {
  cursor: default;
}
.black-outline-button:focus {
  outline: none;
}
.black-outline-button:hover {
  background-color: #303030;
  color: #fff;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}