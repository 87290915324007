.page-section {
  padding: 60px 0;
  &.gray {
    background-color: $gray1;
  }
  &.blue {
    background-color: $blue;
    color: $white;
  }
  .page-section-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .page-section-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 1;
  }
  .page-section-content {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.4;
    margin-top: 20px;
  }
  .page-section-desc {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
  }
  .page-section-image {
    img {
      width: 100%;
    }
  }
  .page-section-button {
    display: inline-block;
    border: 1px solid $black3;
    color: $black3;
    padding: 14px 24px;
    transition: background-color .4s, color .4s;
    margin-top: 20px;
    &.white {
      border: 1px solid $white;
      color: $white;
    }
    &:hover {
      background-color: $black3;
      color: $white;
      cursor: default;
      &.white {
        background-color: $white;
        color: $black3;
      }
    }
  }
}

.center {
  text-align: center;
}

.content-section {
  padding: 50px 0;

  .ant-col {
    padding: 0 15px;
  }

  h2 {
    color: $black5;
    font-weight: 600 !important;
    font-size: 36px;
    line-height: 1;
    margin-bottom: 16px;
  }

  .content-section-text {
    color: $black7;
    font-size: 16px;
  }

  img {
    width: 100%;
  }

  @include for-large {
    padding: 30px 15px;
    h2 {
      font-size: 24px;
    }
    .content-section-text {
      font-size: 14px;
    }
  }

  @include for-medium {
    padding: 30px 15px 0 15px;
    .content-section-text:last-child {
      padding-bottom: 30px;
    }
    img {
      padding-bottom: 30px;
    }
  }

  @include for-small {
    padding: 30px 20px 0 20px;
  }
}

.action-section {
  padding: 50px 0;
  text-align: center;

  h2 {
    color: $white;
    font-weight: 600 !important;
    font-size: 36px;
    line-height: 1;
    margin-bottom: 16px;
  }

  .action-section-text {
    color: $gray1;
    font-size: 16px;
  }

  &.white-bg {
    h2 {
      color: $black5;
    }
    .action-section-text {
      color: $black7;
      font-size: 16px;
    }
  }

  @include for-large {
    padding: 30px;
    h2 {
      font-size: 24px;
      line-height: 1.2;
    }
    .action-section-text {
      font-size: 14px;
    }
  }
}

.white-bg {
  background-color: $white;
}

.gray-bg {
  background-color: $gray1;
}

.blue-bg {
  background-color: $blue;
}

.text-center {
  text-align: center;
}

.text-white {
  color: $white;
}

.page-image {
  height: 500px;
  background-position: center;
  background-size: cover;

  &-overlay {
    background-color: rgba($color: $black, $alpha: 0.3);
    padding: 30px;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
  }

  &-title {
    font-size: 70px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1;
  }

  &-subtitle {
    font-size: 50px;
    font-weight: 200;
    line-height: 0.9;
  }

  img {
    position: absolute;
  }

  &.weddings {
    background-image: url('../images/slide_2.jpg');
  }
}

.content-section-button {
  @include black-outline-button;
  margin-top: 20px;
}

.page-title-section {
  background-color: $white;
  text-align: center;
  padding: 30px;
  .page-title-1 {
    font-weight: 200;
    font-size: 40px;
    color: $black7;
    line-height: 1;
    @media (max-width: 768px) {
      font-size: 30px;
    }
  }
  .page-title-2 {
    font-weight: 600;
    font-size: 60px;
    text-transform: uppercase;
    color: $black5;
    line-height: 1;
    @media (max-width: 768px) {
      font-size: 40px;
    }
  }
}

.page-image-section {
  background-color: $white;
  text-align: center;
  // height: 400px;
  // overflow: hidden;

  img {
    width: 95%;
    // margin-bottom: 40px;
    // box-shadow: 0px 10px 10px 0 rgba(0, 0, 0, .3);
  }
}

.ant-card-meta-title {
  text-align: center;
  font-size: 20px;
}