.logo {
  height: 64px;
  float: left;

  img {
    height: 40px;
  }
}

.ant-layout-header {
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, .3);

  @media (max-width: 768px) {
    padding-right: 6px;
  }
}

.menu-icon {
  display: none;
  font-size: 30px;
  float: right;
  color: #909090;

  button {
    height: 64px;
    width: 64px;
    background-color: transparent;
    border: none;
    outline: none;
  }

  @media (max-width: 768px) {
    display: inline-block;
  }
}