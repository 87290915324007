.app-container {
  height: 100vh;

  .menu-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
  }

  .content-container {
    margin-top: 60px;
  }
}