@media (max-width: 768px) {
  .md-p-20 {
      margin-bottom: 20px;
  }
}

.modal-section-title {
  font-weight: bold;
}

.modal-section-text {
  margin-bottom: 10px;
}